<script setup>


const props = defineProps({
  data: Object
});

const script = ref();

onMounted(() => {
  useHead({
    script: [
      {
        src: '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
        async: true,           
      }
    ]
  })

  if (window.Trustpilot ) {    
    const trustbox = document.getElementById('trustbox');    
    window.Trustpilot.loadFromElement(trustbox.children[0]);
  }
})

</script>

<template>
  <div class="block trust-pilot">
    <div class="container">
      <div class="row">
        <div class="col" v-html="data.trustPilot.script" ref="script" id="trustbox"></div>
      </div>
    </div>
  </div>
</template>